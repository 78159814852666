export const environment = {
  baseUrl: 'https://folio.airbus-val.aodocs.app/',
  apiUrl: 'api/',
  clientId: '767277413873-csk7fa14lb60jg95tkj8mqbur593tg64.apps.googleusercontent.com',
  aodocsUrl: 'https://airbus-val.aodocs.app/',
  version: '4.2.0',
  production: false,
  firebase: {
    apiKey: "AIzaSyA7Bf0yt4x2oyF2IMxhVnPSC82-8Yb7hIo",
    authDomain: "aodocs-core-airbus-val.firebaseapp.com",
    databaseURL: "https://aodocs-core-airbus-val.firebaseio.com",
    projectId: "aodocs-core-airbus-val",
    storageBucket: "aodocs-core-airbus-val.appspot.com",
    messagingSenderId: "586886984461",
    appId: "1:586886984461:web:14949e566d7d71183fb644",
    measurementId: ""
  }
};
